import React ,{ useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { connect } from 'react-redux'
import logo from './logo.svg';
import './App.css';
import MainViewer from './viewer/MainViewer.js';
import MainViewer3D from './viewer3d/MainViewer.js';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox.js';
import AppLeftPanel from './viewer/component/AppLeftPanel.js';
import { setAuthInfo, setLoading, setBookmarkList} from './actions/main.js'
import { setOpenCompanyProfile, setLeftBoothList, setBookmardUpdated, setOpenLogin, setLeftSelectedCategory, setLeftPage, setOpenCompanyModal, setEditCompany, setSnackMsg, setOpenSnack } from './actions/ui.js'
import { parseToken, getTokenCookie, setCookie,assetUrl, getTokenPlanner } from './utils/Common.js'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  BrowserRouter as Router,
  useLocation
} from "react-router-dom";
import qs, { parse } from 'qs'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import { useParams } from 'react-router-dom'
import { API_URL, ESHOW_URL } from './config.js'
import axiosInstance from './utils/axiosInstance.js'
import IconButton from '@mui/material/IconButton';

import BookmarkBorderSharpIcon from '@mui/icons-material/BookmarkBorderSharp';
import BookmarkSharpIcon from '@mui/icons-material/BookmarkSharp';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const responsive1 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2.5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  
  const theme = useTheme();
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [exhibitorlist, setExhibitorlist] = useState([]);
  const [loaded, setLoaded] = useState(false)
  const [page, setPage] = React.useState(1);
  const page_size = 24;
  

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display:'block'
  }));



  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    initForm()
  }, [id5, id4, id3, id2, id1])


  const initForm = async()=>{
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }

    if(1){
      {
        if(1){
          setLoaded(false)
          var result = await getExhibitorList()
          setExhibitorlist(result)
        }
        setLoaded(true)        
      }
    }
  }
  
  const getExhibitorList = async ()=>{
    var result = null
    var token = false
    try{
      token =  getTokenPlanner();
      if(props.role=='admin'){
        token = getTokenCookie()
      }
      else{
        if(!token){
          token = getTokenCookie()
        }
      }
    }
    catch(e){

    }
    
    var booth = props.ui.openCompanyProfile.booth;
    const options = {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }  
    var url = `${ESHOW_URL()}?method=getExhibitorList`
   
    const response = await axios.get(url, options);
    if(response.status == 200){
      if(response.data?.SUCCESS == true){
        result = response.data?.EXHIBITORS;
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(response.data?.MESSAGE?response.data?.MESSAGE:"Something went wrong");
      }
        
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg("Something went wrong");
    }
    return result

 }
 
 const callBookmarkApi= async (exhibitor, value)=>{
  const _token = getTokenPlanner();
  var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
  if(value){
    BOOKMARKS = [
      ...BOOKMARKS,
      {
        COMPANY_NAME: exhibitor?.company?exhibitor?.company:(exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:""),
        NOTES:"",
        STATUS:'',
        BOOTHS:exhibitor.BOOTHS,
        EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
      }
    ]
  }
  else{
    BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
  }
  
  var postData =
  {
    SUCCESS:true,
    BOOKMARKS:BOOKMARKS
  };

  var formdata = {
    query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
    postData: postData,
    returnResponse: true
  }
  var data = qs.stringify({
      request: JSON.stringify(formdata)
  });
  const options = {
    headers: {
      'body': '[{"company:","test"}]', 
      'Content-Type': 'application/json'
    }
  }
  var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
  axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
    const eshowResponse = res.data;
    if(eshowResponse.SUCCESS == true){
    }
    else{
      props.setOpenSnack(true);
      props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to save bookmark");
    }
    props.setBookmarkList(BOOKMARKS)
  }).catch(error => {
      console.log("error", error);
  })
}

 const doBookmark = (exhibitor)=>{
  if(exhibitor){
    const _token = getTokenPlanner();
    if((props.role !='admin') && (!_token || !_token)){
      props.setOpenLogin(true)
      return false;
    }
    var newBoothList = [...props.ui.leftBoothList];
    for(var i =  0; i< newBoothList.length; i++){
      if(`${newBoothList[i]['EXHIBITOR_KEY']}` == `${exhibitor.EXHIBITOR_KEY}`){
        callBookmarkApi(exhibitor, !newBoothList[i]['bookmark'])
        newBoothList[i]['bookmark'] = !newBoothList[i]['bookmark']
      }
    }
    props.setLeftBoothList(newBoothList)
    // props.setBookmardUpdated(true)
  }
}


  console.log("render client app")
  console.log("props.main.showSetup", props.main.showSetup);
  var pageData = paginate(data, page_size, page);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var download_show = true
  var contact_show = true
  if(props.main.showSetup?.PUBLIC?.DOWNLOAD_LINKS == 'hide'){
    download_show = false
  }
  if(props.main.showSetup?.PUBLIC?.CONTACT_INFO == 'hide'){
    contact_show = false
  }
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }
  return (
    <Box id="xhbt-main-container" className="App Client">
      {banner && banner!=''&&
        <Box style={{background:'white', height:'150px'}}>
          <img style={{maxWidth:'100%', height: '150px', objectFit:'cover'}} src={assetUrl(banner)}/>
        </Box>
      }
      <Box style={{maxWidth: 1200, margin:'0px auto 60px', paddingTop: 10, paddingLeft: 10, paddingRight:10}}>
        {
          exhibitorlist?.map((exhibitor, index)=>{
            return(
              <ListItem 
                button 
                key={index}
                secondaryAction={
                  <>
                  {exhibitor.booth?.signed_pdf && props.role == "admin" && 
                  <IconButton edge="end"  download href={API_URL()+"/content/contract/signature/"+exhibitor.booth?.signed_pdf} >
                    {
                      <PictureAsPdfIcon/>
                    }
                  </IconButton>
                  }
                  {sales_bookmarks_show && 
                  <IconButton edge="end" onClick={()=>{ doBookmark(exhibitor)}}>
                    {
                      exhibitor.bookmark?
                      <BookmarkSharpIcon style={{color:'#e54839'}}/>:
                      <BookmarkBorderSharpIcon/>
                    }
                  </IconButton>
                 }
                  </>
                  
                }
              >            
                <ListItemText 
                  primary={
                    <Box display="flex">
                      <Box className="leftPrimaryTxt" style={{width:(props.main.showSetup?.MAP_LOCATION == 'show'?'55%':'80%'), fontSize:'14px'}}
                        onClick={()=>{}} 
                      >
                        {(exhibitor.COMPANY_NAME?(` ${exhibitor.COMPANY_NAME}`):"")}
                      </Box>
                      {props.main.showSetup?.MAP_LOCATION == 'show' &&
                      <Box className="leftPrimaryTxt" style={{width:'25%', textAlign:'center'}}
                        onClick={()=>{}} 
                      >
                        {(exhibitor.MAP_NAME?(` ${exhibitor.MAP_NAME}`):"")}
                      </Box>
                      }
                      <Box pl={1} style={{width:'20%', fontSize:14.5, color: "#505050", textAlign:'center'}}
                        onClick={
                          ()=>{
                            
                          }
                        } 
                      >
                        <b>{(exhibitor.BOOTHS?exhibitor.BOOTHS:"")}</b>
                      </Box>
                    </Box>
                  } 
                />
              </ListItem>
            )
          })
        }
      </Box>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
      }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
    setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
    setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
    setLeftBoothList:  data=>dispatch(setLeftBoothList(data)),
    setBookmardUpdated:   data=>dispatch(setBookmardUpdated(data)),
    setBookmarkList:  data=>dispatch(setBookmarkList(data)),
    setLeftSelectedCategory:  data=>dispatch(setLeftSelectedCategory(data)),
    setLeftPage: value=>dispatch(setLeftPage(value)) ,
    setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
    setEditCompany: value=>dispatch(setEditCompany(value)),
    setSnackMsg: data=>dispatch(setSnackMsg(data)),
    setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);
