import * as React from 'react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from '@mui/icons-material/CloudUpload';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import LayersIcon from '@mui/icons-material/Layers';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import { API_URL, S3_URL } from '../../config.js'
import axios from 'axios';
import qs, { parse } from 'qs'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { Canvg } from 'canvg';
import { setLoading } from '../../actions/main'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setBoothHistory } from '../../actions/booth_history'
import { fabric } from "fabric";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { v4 as uuidv4 } from 'uuid';
import {  px2unit, unit2Px } from '../../utils/CanvasTool'
import IconButton from '@mui/material/IconButton';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {PaperComponent} from '../../utils/Common'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <CloudUploadOutlinedIcon />,
    2: <LayersIcon />,
    3: <InsertPhotoOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};
const steps = ['Select Background Source', 'Select Layer', 'Final image'];
var moveTimer = -1
var step2Canvas
var g_offsetX_Ft
var g_offsetY_Ft
var g_isDragging = false
function BackgroundModal(props) {
  const { onClose, onOk, onChange, open } = props;
  const [step, setStep] = React.useState(1);
  const [filetype, setFiletype] = React.useState("dwg");
  const [imgUrl, setImageUrl] = React.useState(null);
  const [dwgSvg, setDwgSvg] = React.useState(null);
  const [dwgFilteredSvg, setDwgFilteredSvg] = React.useState(null);
  const [mainLayers, setMainLayers] = React.useState([])
  const [imageWidth, setImageWidth] = React.useState(0)
  const [imageHeight, setImageHeight] = React.useState(0)
  const [imageWidth_Ft, setImageWidth_Ft] = React.useState(0)
  const [imageHeight_Ft, setImageHeight_Ft] = React.useState(0)
  const [factor, setFactor] = React.useState(1)
  const [prepareDwgimg, setPrepareDwgimg] = React.useState(false)
  const [preparePreviewDwgimg, setPreparePreviewDwgimg] = React.useState(false)  
  const [offsetX_Ft, setOffsetX_Ft] = React.useState(0)
  const [offsetY_Ft, setOffsetY_Ft] = React.useState(0)
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [fileName, setFileName] = React.useState(false)
  var mainCanvas = props.main.mainCanvas
  
  const openRef = useRef();
  var bgImgCanvas = props.main.bgImgCanvas

  // useEffect(() => {
  //   if(step == 3){
  //     if(filetype == "dwg"){
  //       var drawermain = SVG(dwgSvg);
  //       for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
  //         var layer = mainLayers[layerInd];
  //         if(layer && layer.active){
  //             var topgroup = drawermain.find("#"+layer['id']);
  //             if(topgroup && topgroup.length > 0){
  //                 var secondgroups = topgroup[0].find("g");
  //                 if(secondgroups.length > 0){
  //                     var paths =secondgroups[0].find('path');
  //                     console.log(paths);
  //                     for(var i = 0; i < paths.length; i++){
  //                         var path = paths[i];
  //                         var pathbox = path.bbox();
  //                         var drawer = SVG();
  //                         var group = drawer.group();
  //                         group.attr({
  //                             fill:'none',
  //                             stroke:"black"
  //                         })
  //                         path.move(0,0)
  //                         var svgPath = SVG(path);
  //                         group.add(svgPath);
  //                         console.log(drawer.svg());
  //                         drawer.css({position:'absolute', left: pathbox.x, top: pathbox.y})
  //                         drawer.addTo('.render-svg').size(pathbox.width, pathbox.height)
  //                     }
  //                 }
  //             }
  //         }
          
  //       }
  //       for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
  //         var layer = mainLayers[layerInd];
  //         if(layer && layer.active){
  //             var topgroup = drawermain.find("#"+layer['id']);
  //             if(topgroup && topgroup.length > 0){
  //                 var secondgroups = topgroup[0].find("g");
  //                 if(secondgroups.length > 0){
  //                     var paths = secondgroups[0].find('text');
  //                     console.log(paths);
  //                     for(var i = 0; i < paths.length; i++){
  //                         var path = paths[i];
  //                         var boothName = path.text();
  //                         console.log("boothname", boothName);
  //                         var parentGroup = path.parents(SVG.G);
  //                         var pathbox = path.bbox();
  //                         var pathtransform = path.transform();
  //                         var drawer = SVG();
  //                         var group = drawer.group();
  //                         group.attr({
  //                             fill:'black',
  //                             stroke:"black",
  //                             color: 'black',
  //                             'font-size':'26.6497',
  //                             "font-family": "arial, helvetica, sans-serif"
  //                         })
  //                             path.transform({
  //                                 translate:[0,0]
  //                             })
  //                         var svgPath = SVG(path);
  //                         group.add(svgPath);
  //                         console.log(drawer.svg());
  //                         drawer.css({position:'absolute', left: pathbox.x, top: pathbox.y})
  //                         drawer.addTo('.render-svg').size(pathbox.width, pathbox.height)
                          
  //                     }                    
  //                 }
  //             }
  //         }
  //       }
        
  //       setDwgFilteredSvg(drawermain.svg());
  //     }
  //   }
  // }, [step])

  const onSet = async ()=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var mainCanvas = props.main.mainCanvas;
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)

    var bgImgCanvas = props.main.bgImgCanvas;
    var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
    var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
    var firstBackground = props.main.firstBgContent&&props.main.firstBgContent.backgroundImage?props.main.firstBgContent.backgroundImage:null
    if(imgUrl){
      if(filetype == "image" || filetype == "pdf"){

        bgImgCanvas.setBackgroundImage(imgUrl, pushBoothHistory.bind(bgImgCanvas), {
          // opacity: 0.5,
          angle: 0,
          left: offsetX_PX+50+mapOffsetX,
          top: offsetY_PX+50+mapOffsetY,
          originX: 'left',
          originY: 'top',
          scaleX: factor,
          scaleY: factor,
          crossOrigin: 'anonymous',
          modified: true,
          KEY_ID: firstBackground?firstBackground.KEY_ID:uuidv4(),
          bgFileName: fileName
        });
        mainCanvas.renderAll()
      }
      else if(filetype == "dwg"){
        // var canvas = new Canvas("mycanvas",{
        //   width: imageWidth,
        //   height: imageHeight
        // })
        if(dwgFilteredSvg){
          // const canvas = document.querySelector('canvas');
          // const ctx = canvas.getContext('2d');
    
          // var v = await Canvg.from(ctx, dwgFilteredSvg);
    
          // // Start SVG rendering with animations and mouse handling.
          // v.start();
          // var img_url = canvas.toDataURL("image/png")
    
          var mainCanvas = props.main.mainCanvas;
          bgImgCanvas.setBackgroundImage(imgUrl, pushBoothHistory.bind(bgImgCanvas), {
            // opacity: 0.5,
            angle: 0,
            left: offsetX_PX+50,
            top: offsetY_PX+50,
            originX: 'left',
            originY: 'top',
            scaleX: factor,
            scaleY: factor,
            crossOrigin: 'anonymous',
            modified: true,
            KEY_ID: firstBackground?firstBackground.KEY_ID:uuidv4(),
            bgFileName: fileName

          });
          
          bgImgCanvas.renderAll()
          // v.stop()
        }
      }
    }
    else{
      bgImgCanvas.setBackgroundImage(null, bgImgCanvas.renderAll.bind(bgImgCanvas));
    }
    bgImgCanvas.bgFileName = fileName
    onOk();
  }

  const pushBoothHistory = ()=>{    
    var bgImgCanvas = props.main.bgImgCanvas;
    bgImgCanvas.renderAll();
    bgImgCanvas.requestRenderAll()
    // props.setBoothHistory({content: (mainCanvas.toDatalessObject())})
  }

  const previewCallback = ()=>{    
    step2Canvas.renderAll();
    step2Canvas.requestRenderAll()

  }

  useEffect( async () => {
    if(prepareDwgimg && dwgSvg){
      // if(filetype == "dwg" && document.getElementById("render-svg")){
      if(filetype == "dwg"){
        // document.getElementById("render-svg").innerHTML = ""
        var drawermain = SVG(dwgSvg);
        var bbox = drawermain.bbox();
        var width = bbox.width.toFixed(1)
        var height = bbox.height.toFixed(1)
        setImageWidth(width)
        setImageHeight(height)
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        setImageWidth_Ft(px2unit(width,convertRateX,props.main.mainCanvas.mapUnit))
        setImageHeight_Ft(px2unit(height,convertRateX,props.main.mainCanvas.mapUnit))
        drawermain.width(642)
        drawermain.height(500)
        drawermain.viewbox(0,0,width,height)
        drawermain.transform({
          a:1,b:0,c:0,d:1,e:0,f:0
        })

        // drawermain.addTo('.render-svg')
        for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
          var layer = mainLayers[layerInd];
          var topgroup = drawermain.find("#"+layer['id']);
          if(layer && !layer.active){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          if(layer && layer.active){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                if(subGroups[0][i].node.style.fill!= ''){
                  subGroups[0][i].node.style.fill="transparent"
                }
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
            }
          }
        }

        var tmp_main = SVG(dwgSvg);
        for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
          var layer = mainLayers[layerInd];
          var topgroup = tmp_main.find("#"+layer['id']);
          if(layer && !layer.active){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          if(layer && layer.active){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                subGroups[0][i].node.style.fill="transparent"
                const computedStyle = window.getComputedStyle(subGroups[0][i].node);
                const strokeWidth = computedStyle.getPropertyValue('stroke-width');

                console.log("strokeWidth", strokeWidth)
                subGroups[0][i].node.style.stroke="black"
                let idealWidth = 2; //2feet
                if(props.main.mainCanvas.mapUnit=='meter'){
                  idealWidth = 2/20 //10cm
                  if(props.main.mainCanvas.convertUnit == 'mm'){
                    idealWidth = 20 //20cm
                  }
                }
                subGroups[0][i].node.style.strokeWidth=(strokeWidth?strokeWidth:1)*Math.min(idealWidth/(convertRateX?convertRateX:1),10)
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        var svgstring = tmp_main.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/&nbsp;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");
        setDwgFilteredSvg(svgstring);        
        // console.log("svg", svgstring)
        // let blob = new Blob([svgstring], {type: 'image/svg+xml;charset=utf-8'});
        // // var blob = new Blob(drawermain.svg(), {type: 'image/svg+xml;charset=utf-8'});
        // var canvas = document.createElement("canvas");
        // canvas.width=width;
        // canvas.height=height;
        // const ctx = canvas.getContext('2d');
        // var v = await Canvg.from(ctx, svgstring);
  
        // // // Start SVG rendering with animations and mouse handling.
        // v.start();
        // var img_url = canvas.toDataURL("image/png")
        // v.stop()

        var filename = "tmp";
        // var data = blob.slice(0, blob.size);
        var data = svgstring
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`uploadbgimg?ax-allow-ext=all&listtype=serverfolder&filePath=${filePath}&&start=0`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              console.log(xhr.responseText);
              
              // var objecturl = URL.createObjectURL(file);
              var objecturl = xhr.responseText
              setImageUrl(objecturl)
          }
          setLoadingFlag(false);
        };

        xhr.send((data));
        setLoadingFlag(true);
      }
      setPrepareDwgimg(false)
      setPreparePreviewDwgimg(true)
    }
  }, [prepareDwgimg, dwgSvg])


  useEffect(() => {
    if(open){
      setTimeout(()=>{
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)  
        var bg_url = null;
        var bg_width = 0;
        var bg_height = 0;
        var bg_offset_x = 0;
        var bg_offset_y = 0;
        var bg_scale = 1;
        var bg_width_feet = 0;
        var bg_height_feet = 0;
        var bg_offset_x_feet = 0;
        var bg_offset_y_feet = 0;
        var backgroundImage = bgImgCanvas.backgroundImage
        if(backgroundImage && backgroundImage.src){
          bg_url = backgroundImage.src
        }
        else if(backgroundImage && backgroundImage._element){
          bg_url = backgroundImage._element.currentSrc
        }
        if(backgroundImage){
          
          var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
          var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
          bg_width = backgroundImage.width?backgroundImage.width:3000
          bg_height = backgroundImage.height?backgroundImage.height:3000
          bg_scale = backgroundImage.scaleX
          bg_offset_x = backgroundImage.left?backgroundImage.left-50-mapOffsetX:0
          bg_offset_y = backgroundImage.top?backgroundImage.top-50-mapOffsetY:0
        }
        setImageUrl(bg_url);
        var factor = bg_scale?bg_scale:1
        setFactor(factor)
        setImageWidth(bg_width)
        setImageHeight(bg_height)
        var offsetX_Ft = px2unit(bg_offset_x,convertRateX,props.main.mainCanvas.mapUnit)
        var offsetY_Ft = px2unit(bg_offset_y,convertRateX,props.main.mainCanvas.mapUnit)
        setOffsetX_Ft(offsetX_Ft)
        setOffsetY_Ft(offsetY_Ft)
        setImageWidth_Ft(px2unit(bg_width,convertRateX,props.main.mainCanvas.mapUnit))
        setImageHeight_Ft(px2unit(bg_height,convertRateX,props.main.mainCanvas.mapUnit))

        setFiletype(bg_url?'image':"dwg")
        setPrepareDwgimg(true)  
        setFileName(bgImgCanvas.bgFileName)    
      
      // setPreparePreviewDwgimg(true)
      // setTimeout(()=>{
        initCanvas(bg_url, 'image', offsetX_Ft, offsetY_Ft, factor);
      // }, 500)
      }, 500)
    }
  }, [open])

  const dollyIn = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 1.3;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const dollyOut = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 0.8;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
  }
  const swichFullView = ()=>{    
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
          mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
          mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
        }
    }
    if(mapWidth && mapHeight){
        var zoomX = (642)/(mapWidth)
        var zoomY = (500)/(mapHeight)
        var zoom = Math.min(zoomX, zoomY)*0.95
        step2Canvas.viewportTransform[0] = zoom;
        step2Canvas.viewportTransform[3] = zoom;
        step2Canvas.viewportTransform[4] = 0;
        step2Canvas.viewportTransform[5] = 0;

        step2Canvas.requestRenderAll();
        if(Math.min(zoomX, zoomY) > 0){
            var deltaX = (642 - mapWidth*zoom)/2;
            var deltaY = (500 - mapHeight*zoom)/2;
            var delta = new fabric.Point(deltaX, 35);
            step2Canvas.relativePan(delta);
        }


    }
  }
  const onMouseDown = (opt, that)=>{           
    var evt = opt.e;
    g_isDragging = true; 
    that.selection = false;
    that.lastPosX = evt.clientX;
    that.lastPosY = evt.clientY;
  }

  const initCanvas = (imgUrl, filetype, offsetX_Ft, offsetY_Ft, factor)=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    step2Canvas = new fabric.Canvas("step-2-canvas");
    step2Canvas.set({preserveObjectStacking: true, altActionKey: 'ctrlKey', altSelectionKey: 'ctrlKey'})
    step2Canvas.defaultCursor = 'pointer';
    step2Canvas.off('mouse:down');
    step2Canvas.on('mouse:down', function(opt) {
        onMouseDown(opt, this)            
    });
    
    step2Canvas.off('mouse:up');
    step2Canvas.on('mouse:up', function(opt) { 
      g_isDragging = false; 
    });
    step2Canvas.off('mouse:move')    
    step2Canvas.on('mouse:move', function(opt) {
        var evt = opt.e;
        if (g_isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          var deltaX = e.clientX - this.lastPosX;
          var deltaY = e.clientY - this.lastPosY;
          vpt[4] += deltaX;
          vpt[5] += deltaY;
          this.requestRenderAll();

          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
    });

    var content = props.main.mainCanvas.toDatalessObject()
    step2Canvas.loadFromJSON(content, ()=>{
      step2Canvas.discardActiveObject();
        step2Canvas.forEachObject(function(object) {
            object.evented = false;
            object.selectable = false; 
            object.selection=false;
            object.hasControls=false;
            object.hasBorders=false;
            object.lockMovementX= true;
            object.lockMovementY= true;
            object.lockRotation= true;
            object.lockScalingX= true;
            object.lockScalingY= true;
        });

      var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
      var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)


      var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
      var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
      if(imgUrl){
        if(filetype == "image" || filetype == "pdf"){

          step2Canvas.setBackgroundImage(imgUrl, previewCallback.bind(step2Canvas), {
            opacity: 0.5,
            angle: 0,
            left: offsetX_PX+50+mapOffsetX,
            top: offsetY_PX+50+mapOffsetY,
            originX: 'left',
            originY: 'top',
            scaleX: factor,
            scaleY: factor,
            crossOrigin: 'anonymous'
          });
        }
        else if(filetype == "dwg"){
          if(dwgFilteredSvg){
            step2Canvas.setBackgroundImage(imgUrl, previewCallback.bind(step2Canvas), {
              // opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: factor,
              scaleY: factor,
              crossOrigin: 'anonymous'
            });
            // v.stop()
          }
        }
      }
      else{
        step2Canvas.setBackgroundImage(null);
      }
      swichFullView();
      setLoadingFlag(false)

    })
  }

  const previewRender = ()=>{
    if(!step2Canvas) return;
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)


    var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
    var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
    if(imgUrl){
      if(filetype == "image" || filetype == "pdf"){

        step2Canvas.setBackgroundImage(imgUrl, previewCallback.bind(step2Canvas), {
          opacity: 0.5,
          angle: 0,
          left: offsetX_PX+50+mapOffsetX,
          top: offsetY_PX+50+mapOffsetY,
          originX: 'left',
          originY: 'top',
          scaleX: factor,
          scaleY: factor,
          crossOrigin: 'anonymous'
        });
      }
      else if(filetype == "dwg"){
        if(dwgFilteredSvg){
          step2Canvas.setBackgroundImage(imgUrl, previewCallback.bind(step2Canvas), {
            // opacity: 0.5,
            angle: 0,
            left: offsetX_PX+50+mapOffsetX,
            top: offsetY_PX+50+mapOffsetY,
            originX: 'left',
            originY: 'top',
            scaleX: factor,
            scaleY: factor,
            crossOrigin: 'anonymous'
          });
          // v.stop()
        }
      }
    }
    else{
      step2Canvas.setBackgroundImage(null).renderAll();
    }
    setLoadingFlag(false)
  }

  useEffect(() => {
    if(preparePreviewDwgimg){
      setLoadingFlag(true)
      setTimeout(function(){
        previewRender()
      },100)
    }
    
    setPreparePreviewDwgimg(false)
  }, [preparePreviewDwgimg])

  useEffect(() => {
      setLoadingFlag(true)
      setTimeout(function(){
        previewRender()
      },100)
  }, [imgUrl])
  

  useEffect( async () => {
    if(step2Canvas){      
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
      var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
      var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
      if(filetype== "pdf" || filetype== "image" || filetype== "dwg"){
        step2Canvas.setBackgroundImage(imgUrl, step2Canvas.renderAll.bind(step2Canvas), {
          opacity: 0.5,
          angle: 0,
          left: offsetX_PX+50+mapOffsetX,
          top: offsetY_PX+50+mapOffsetY,
          originX: 'left',
          originY: 'top',
          scaleX: factor,
          scaleY: factor,
          crossOrigin: 'anonymous',
        });
      }
      else{
        var objects = step2Canvas.getObjects();
        for(var i = 0; i < objects.length; i++){
          if(objects[i].get('class') == 'bg'){
            objects[i].set({
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              scaleX: factor,
              scaleY: factor
            })          
            step2Canvas.renderAll();
            break;
          }
        }
      }
    }
    g_offsetX_Ft = offsetX_Ft
    g_offsetY_Ft = offsetY_Ft
  }, [factor, offsetX_Ft, offsetY_Ft])
  
  const handleFiletype = (type)=>{    
    setFiletype(type)
    if(type == "dwg"){
      setPrepareDwgimg(true)
    }
  }

  // useEffect(() => {
  //   if(step == 2){
  //     setPreparePreviewDwgimg(true)
  //   }
  //   if(step == 3){
  //     setPrepareDwgimg(true)
  //   }
  // }, [step, open])

  const parseLayer = (svgStr)=>{
    if(svgStr){
        var drawermain = SVG(svgStr);
        var layerGroups = drawermain.find("[id*='layer_']");
        var layers = [];
        if(layerGroups && layerGroups.length>0){
            for(var loop = 0; loop < layerGroups.length; loop++){
                var layerGroup = layerGroups[loop];
                if(layerGroup){
                    var layerid = layerGroup.attr('id');
                    var layername = layerGroup.attr('cvjs:layername')
                    console.log("layername", layername)
                    console.log("layerid", layerid)
                    layers.push({id:layerid, name:layername, active:false})
                }
            }
        }
        return layers;
    }
}

  const getConvertedSvg=(convertedSvgInfo) => {
    if(convertedSvgInfo){
        axios.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(res=>{
            const result = res.data;
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(result, "application/xml");
            // var svgDoc = doc.contentDocument;
            var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
            if(svgStr){
                var layers = parseLayer(svgStr)                        
                setMainLayers(layers)                
            }
            setDwgSvg(svgStr); 
        }).catch(error => {
            console.log("error", error);
        })
            
    }
  }

  const handleListItemClick = (value) => {
    // onClose(value);
    console.log("handleListItemClick", value)
    var tmp = [...mainLayers];
    for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['id'] == value){
            tmp[i] = {...tmp[i], active:!tmp[i]['active'], selected: !tmp[i]['active']};
        }
        else{
          tmp[i] = {...tmp[i], selected: false};
        }
    }
    setMainLayers(tmp)
    setPrepareDwgimg(true)
  };

  const onChangeFile = async (e)=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var file = openRef.current.files[0]
    var filename = file.name
    if(file){
      setFileName(filename)
      if(filetype == "dwg"){
        var data = file.slice(0, file.size);
        let last_dot = filename.lastIndexOf('.')
        let ext = filename.slice(last_dot + 1)
        var filePath = `dwg/${uuidv4()}.${ext}`
        var url = `${S3_URL()}uploadfile?filePath=${filePath}` 
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        setLoadingFlag(true)
        const response = await axios.post(url, data, headers);
        if(response.status == 200){
          var formdata = 
          {
              "action":"svg_creation",
              "converter":"AutoXchange AX2020",
              "version":"V1.00",
              "contentType":"file",
              "contentLocation":`${S3_URL()}s3/${filePath}`,
              "contentFormat":"DWG",
              "contentUsername":"",
              "contentPassword":"",
              "userLabel":"fromCADViewerJS",
              "contentResponse":"stream",
              "leaveStreamOnServer":0,
              "parameters":[
                {"paramName":"f",
                "paramValue":"svg"},
                // {"paramName":"RL",
                // "paramValue":"RM_"},
                // {"paramName":"TL","paramValue":"RM_TXT"},
                // {"paramName":"LA","paramValue":""},
                {"paramName":"model","paramValue":""},
                {"paramName":"fpath","paramValue":"/converters/ax2020/windows/fonts/"},
                {"paramName":"WIDTH","paramValue":"10000"},
                {"paramName":"HEIGHT","paramValue":"10000"},
             ]
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
            });
          // var bodyFormData = new FormData();
          // bodyFormData.append('request', JSON.stringify(formdata));
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          axios.post('/callapiconversion', data, options).then(res=>{
              const result = res.data;
              getConvertedSvg(result);
              setLoadingFlag(false)
          }).catch(error => {
              console.log("error", error);
              setLoadingFlag(false)
          })
        }
        
      }
      if(filetype== "pdf"){
        var filename = file.name
        const reader = new FileReader();
        var data = file.slice(0, file.size);
        var filePath = `map_bg/${uuidv4()}`
        var url = API_URL()+`convertpdf2pic?ax-allow-ext=all&listtype=serverfolder&ax-file-name=${filename}&&filePath=${filePath}`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        setLoadingFlag(true)

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              console.log(xhr.responseText);
              var objecturl = xhr.responseText
              setImageUrl(objecturl)
              var img = new Image();
              img.onload = function(){
                setImageWidth(this.width)
                setImageHeight(this.height)
                setImageWidth_Ft(px2unit(this.width,convertRateX,props.main.mainCanvas.mapUnit))
                setImageHeight_Ft(px2unit(this.height,convertRateX,props.main.mainCanvas.mapUnit))
              }
              img.src = objecturl
          }
          setLoadingFlag(false);
        };

        xhr.send((data));
        setLoadingFlag(true);
      }
      else if(filetype == 'image'){
        var filename = file.name
        const reader = new FileReader();
        var data = file.slice(0, file.size);
        var filePath = `map_bg/${uuidv4()}`
        var url = `${S3_URL()}uploadfile?filePath=${filePath}`
        setLoadingFlag(true)
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        const response = await axios.post(url, data, headers);
        if(response.status == 200){
          var objecturl = `${S3_URL()}s3/${filePath}`
            setImageUrl(objecturl)
            var img = new Image();
            img.onload = function(){
              setImageWidth(this.width)
              setImageHeight(this.height)
              setImageWidth_Ft(px2unit(this.width,convertRateX,props.main.mainCanvas.mapUnit))
              setImageHeight_Ft(px2unit(this.height,convertRateX,props.main.mainCanvas.mapUnit))
            }
            img.src = objecturl
        }
        setLoadingFlag(false)
      }
      openRef.current.value = null;
    }
  }

  const moveX = (direction)=>{
    setOffsetX_Ft(g_offsetX_Ft*1+direction)
  }

  const moveY = (direction)=>{
    setOffsetY_Ft(g_offsetY_Ft*1+direction)
  }

  return (
    <Dialog
      id="background-modal"
      fullWidth={true}
      maxWidth={"lg"}      
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'background-dialog-title'}}
      aria-labelledby="background-dialog-title"
      onClose={onClose} open={open}>
      <DialogTitle id='background-dialog-title' style={{display:'flex', alignItems:'center'}}><InsertPhotoOutlinedIcon style={{marginRight: 10}}/> Background</DialogTitle>
      <DialogContent>
        <Box>
            <Box mt={4}>
              <Grid
                container
                spacing={2}
                rowSpacing = {2}
              >
                <Grid item md={12}>
                  <FormControl>
                    <FormLabel component="legend"><b>Source</b></FormLabel>
                    <RadioGroup row
                      aria-labelledby=""
                      value={filetype}
                      onChange={(e)=>handleFiletype(e.target.value)}
                    >
                      <FormControlLabel value="dwg" control={<Radio />} label="DWG" />
                      <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                      <FormControlLabel value="image" control={<Radio />} label="Image (PNG, JPG)" />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <div>
                    <FormControl>
                      <div>
                        <Button variant="contained" onClick={()=>{openRef.current.click()}} color="primary" endIcon = {<FileUpload/>}>
                          Upload
                        </Button>
                        {
                          filetype== "dwg" &&
                          <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)}  accept='.dwg,.dxf'/>
                        }
                        {
                          filetype== "pdf" &&
                          <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)}  accept='.pdf'/>
                        }
                        {
                          filetype!= "dwg" && filetype!= "pdf" &&
                          <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)} />
                        }
                        
                      </div>
                    </FormControl>
                    <FormControl sx={{marginLeft:'10px'}}>
                      <Button variant="contained" onClick={()=>{setImageUrl(null); }} color="primary" endIcon = {<DeleteForeverOutlinedIcon/>}>
                        Delete
                      </Button>
                      
                    </FormControl>
                  </div>                  
                  {
                    fileName&& 
                      <Box mb={1} style={{fontWeight:'15px'}}>
                        {fileName}
                      </Box>
                  }
                </Grid>
                
              </Grid>
            </Box>
            <Box mt={4}>
              <Grid
                container
                spacing={1}
                rowSpacing = {1}
              >
                <Grid item md={3}>
                  {
                    filetype== "dwg" &&
                    <Box mt={0} style={{borderRight:'1px solid rgb(237 237 237)', height:'100%'}}>
                      <List sx={{ pt: 0 }}>
                        {mainLayers.map((layer, index) => (
                          <ListItem key={index} style={{fontSize:12}} button onClick={() => handleListItemClick(layer['id'])}>
                            <ListItemAvatar className="layeravada">
                                {layer['active']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                            </ListItemAvatar>
                            <ListItemText className = "layertext" primary={layer['name']} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  }
                </Grid>
                <Grid item md={9}>
                  <Grid
                      container
                      spacing={2}
                      rowSpacing = {4}
                  >
                    <Grid item md={9}>
                      <canvas id="step-2-canvas" width="642" height="500"/>
                    </Grid>              
                    <Grid item md={3}>
                      <Box pl={2} style={{borderLeft:'1px solid rgb(237 237 237)', height:'100%'}}>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Width"
                            defaultValue="0"
                            value={imageWidth_Ft}
                            onChange={(e)=>setImageWidth(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Height"
                            defaultValue="0"
                            value={imageHeight_Ft}
                            onChange={(e)=>setImageHeight(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Scale"
                            defaultValue="0"
                            value={factor}
                            onChange={(e)=>setFactor(e.target.value)}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Offset X"
                            defaultValue="0"
                            value={offsetX_Ft}
                            onChange={(e)=>setOffsetX_Ft(e.target.value)}
                          />
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-required"
                            label="Offset Y"
                            defaultValue="0"
                            value={offsetY_Ft}
                            onChange={(e)=>setOffsetY_Ft(e.target.value)}
                          />
                        </Box>
                        
                        <div className="set-bg-controls" style={{display:'flex', marginTop:'15px', justifyContent:'center'}}>
                            <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                        </div>
                        <Box className="pan" sx={{mt:'15px'}}>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mb: '5px'}} size="small" type="button" 
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveY(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowUpOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, marginRight:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveX(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronLeftOutlinedIcon />
                            </IconButton>
                            <IconButton sx={{p:0, marginLeft:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveX(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronRightOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mt:'5px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveY(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowDownOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
            </Box>           
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={()=>onSet()}>
          <b>Set</b>
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <LoadingOverlay
        active={loadingFlag}
        styles={{
          // spinner: (base) => ({
          // ...base,
          // width: '50px',
          // '& svg circle': {
          //     stroke: '#1976d2'
          // }
          // }),
          overlay: (base) => ({
          ...base,
          fontSize:'18px',
          color: 'rgb(5, 37, 51)',
          // background: 'rgb(229 229 229 / 92%)',
          background: 'transparent',
          position:'fixed',
          zIndex:1000000000
          })
        }}
        spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
        >
      </LoadingOverlay> 
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
      setBoothHistory: data=>dispatch(setBoothHistory(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(BackgroundModal);