import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { connect } from 'react-redux'
import { setMainLayers } from '../../actions/main'
import { fabric } from "fabric";
import {canvasApp, helperCanvas} from '../../utils/CanvasApp'
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import {getStrokeWidth, addText, checkCombinable, checkContainSold, checkIfSelectable, px2unit} from '../../utils/CanvasTool'
import { setSnackMsg, setOpenSnack } from '../../actions/ui'
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { getDefaultGroup } from '../../utils/Common'
import {PaperComponent} from '../../utils/Common'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const emails = ['username@gmail.com', 'user02@gmail.com'];function GrouppingModal(props) {
  const { onClose, setGrouppingBooth, onOk, open } = props;
  var mainLayers = props.main.mainLayers;
  var mainCanvas = props.main.mainCanvas;
  useEffect(() => {
    groupItemsPreview();
  }, [props.grouppingBooth])
  const handleCloseSnack = ()=>{
    props.setOpenSnack(false);
}
  
  const groupItemsPreview = (newBoothNumber, fromUndo, org_id)=>{
    var convertRateX= 1
    var convertRateY= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var unitRate = 12; //1feet = 12inch
    if(mainCanvas.mapUnit == 'meter'){
      unitRate = 1;
    }
    var feetUnitSize = 1;       

    if(convertRateX){
      feetUnitSize = (unitRate/convertRateX)
    }

    if (mainCanvas && !mainCanvas.getActiveObject()) {
        return;
    }
    if (mainCanvas.getActiveObject().type !== 'activeSelection') {
        return;
    }
    var newBooths = [];
    var groupobject = mainCanvas.getActiveObject();
    var apartFlag = false;//checkifApart(groupobject);
    var objects = groupobject.getObjects();
    
    if(!checkCombinable(objects)){
        props.onClose()
        props.setSnackMsg("Failed to combine");
        props.setOpenSnack(true);
        return 
    }
    
    
    {        
        canvasApp.helperCanvas = mainCanvas
        var outpPutFontSize = 20
        var group = canvasApp.combineHelper.openCombineWindow(true,props.grouppingBooth);
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        
          
          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.left =  0+group.width/2+f_text2.height*0.2
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 552/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log()

          setTimeout(function(){
            document.getElementById("previewSvg").innerHTML = "";
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
            
          },100)
        }
        if(group == false){
          apartFlag = true;
        }
    }
    if(checkContainSold(objects) && apartFlag){
        // props.setSnackMsg("Failed to combine");
        // props.setOpenSnack(true);
        return 
    }
    if(apartFlag){
        var objectsInGroup = groupobject.getObjects();
        let masterLeftOffset=0;
        let masterTopOffset=0;
        var minObject = objectsInGroup[0];
        for(let ii=0;(ii<objectsInGroup.length);ii++){
						if(objectsInGroup[ii].left < minObject.left){
							minObject = objectsInGroup[ii]
						}
						if(objectsInGroup[ii].top < minObject.top){
							minObject = objectsInGroup[ii]
						}
        }
        let coords = [];
        let pointArray = [];
        let masterLeft=0;
        let masterTop=0;
        masterLeftOffset = minObject.left
        masterTopOffset = minObject.top
        var centerPoint = new fabric.Point(masterLeftOffset, masterTopOffset);

        let  newElements = [];
        for(let i=0;(i<objectsInGroup.length);i++){
          var objectOrigin = new fabric.Point(objectsInGroup[i].left, objectsInGroup[i].top);
          angle = minObject.angle
          var new_loc = fabric.util.rotatePoint(objectOrigin, centerPoint, angle*2*Math.PI/360*(-1))
          masterTop=new_loc.y  - Number(masterTopOffset);
          masterLeft=new_loc.x  - Number(masterLeftOffset);
                
          if (typeof objectsInGroup[i]._objects !=='undefined'){
            var angle = objectsInGroup[i].angle?objectsInGroup[i].angle:0
            var childObjects = objectsInGroup[i]._objects.forEach(function(childobj){      
                if (!childobj.type.toUpperCase().includes("TEXT")){
                  pointArray = [];
                  var points = childobj.points
                  var pathOffset = childobj.pathOffset
                  var paths = [];
                  let basicPoint = points[0]
                  for(var j = 0; j < points?.length; j++){      
                    var point = points[j];
                    if((point['x'] + point['y']) < (basicPoint['x'] + basicPoint['y'])){
                      basicPoint = point
                    }
                  }

                  for(var j = 0; j < points?.length; j++){
                    var objectOrigin = false
                    // if(points[0]['x'] == 0 ){
                      objectOrigin = new fabric.Point((points[j]['x']) + Number(masterLeft), (points[j]['y']) +  Number(masterTop));
                    // }
                    // else{
                      objectOrigin = new fabric.Point((points[j]['x'] - basicPoint['x']) + Number(masterLeft) + Number(childobj.left)+(pathOffset.x - basicPoint['x']), (points[j]['y'] - basicPoint['y']) +  Number(masterTop) + Number(childobj.top)+(pathOffset.y- basicPoint['y']));
                    // }
                    paths.push(
                      {x:Math.round((objectOrigin.x)*10)/10, y:Math.round((objectOrigin.y)*10)/10 }
                    )
                  }
                  // coords.push([paths]);
                  let newElement = new fabric.Polygon(
                    paths
                    , {
                  });

                  newElements.push(newElement)
                }
            });
          }
          
        }

        var measureGroup = new fabric.Group(newElements).set({
          class: 'booth',
          class1 : 'booth-group',
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
        });	

        var booth_numbers = [];
        for(var i = 0; i < objects.length; i++){
          booth_numbers.push(objects[i].booth_number);
        }
        booth_numbers.sort(function(a, b) {
          if (a < b) {
              return -1;
          }
          if (a > b) {
              return 1;
          }
          
          // names must be equal
          return 0;
        })
        var orgObject;
        newBoothNumber = props.grouppingBooth

        objectsInGroup.forEach(function(object) {
          if(object.booth_number == newBoothNumber){
            orgObject = object; return;
          }
        });


        var boothId = "G_"+parseInt(Math.random()*(10000))

        var bgColor = "#dadada"
        var element = new fabric.Polygon(
          [
            {x: 0, y:0},
            {x: measureGroup.width, y:0},
            {x: measureGroup.width, y:measureGroup.height},
            {x: 0, y:measureGroup.height},
            {x: 0, y:0}
          ]
          , {
            class:"outline",
            left: minObject.left,
            top: minObject.top,
            fill: bgColor, 
            stroke: 'black',
            strokeWidth: getStrokeWidth(mainCanvas),
            borderColor: 'black',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true
        });
        
        element.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false
        });

        var group = new fabric.Group([element]).set({
            id: org_id?org_id:'booth-'+boothId,
            class: 'booth',
            boothGroup: getDefaultGroup(props.main.groupOptions),
            class1 : 'booth-group',
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            angle: minObject.angle
        });	
        group.set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false,
            layer:'booth',
            booth_number:props.grouppingBooth?props.grouppingBooth:newBoothNumber
        });        
        var newText = (orgObject.company?(orgObject.company+"\n"):"")+(props.grouppingBooth?props.grouppingBooth:newBoothNumber)
        addText(mainCanvas, group, newText)
        if(group){
          var rateX = 200/(group.width);
          var rateY = 150/(group.height);
  
          var fontSize = outpPutFontSize/Math.min(rateX, rateY)
          var textObjects = group.getObjects('text')
          for(var j = 0; j<  textObjects.length; j++){
              textObjects[j].set({fontSize:50/Math.max(convertRateX, 1)})
          }
          mainCanvas.renderAll()
          var decimal = 0;
          if((group.width)/feetUnitSize < 1) decimal = 2
        

          var f_text1 = new fabric.Text(px2unit(group.width, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
            left: 0,
            top: 0,
            fontSize:fontSize,
            class:'size'
          });   
          f_text1.left =  0 - f_text1.width/2
          f_text1.top =  0 - group.height/2 - f_text1.height*1.2
          group.add(f_text1);
          var f_text2 = new fabric.Text(px2unit(group.height, convertRateX, mainCanvas.mapUnit).toFixed(decimal)+(mainCanvas.mapUnit=='feet'?"'":'m'), {
              left: 0+group.width/2,
              top: 0,
              fontSize:fontSize,
              class:'size'
          });    
          f_text2.top =   0 - f_text1.height/2
          group.add(f_text2);
          group.clipPath = null

          var drawermain = SVG();
          

          group.scaleX = Math.min(rateX, rateY)
          group.scaleY = Math.min(rateX, rateY)
          group.left = 552/2 - (group.width)*group.scaleX/2

          group.top = (f_text1.height)*group.scaleX;

          drawermain.svg(group.toSVG())
          console.log()

          setTimeout(function(){
            document.getElementById("previewSvg").innerHTML = "";
            drawermain.remove().addTo('#previewSvg').size('100%', '100%')
            
          },100)
        }
    }
    
  }

  const onChangeName = (e)=>{
    setGrouppingBooth(e.target.value);
  }
  

  var booths = props.boothTexts;
  console.log("booths", booths)
  return (
    <Dialog
      fullWidth={true}
      sx={{maxWidth:'750px', margin:'auto'}}
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'combine-dialog-title'}}
      aria-labelledby="combine-dialog-title"
      onClose={onClose} open={open}>
      <DialogTitle id='combine-dialog-title'>Combine</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* To subscribe to this website, please enter your email address here. We
          will send updates occasionally. */}
        </DialogContentText>
        {/* <TextField
          margin="dense"
          id="name"
          label="Booth Number"
          type="name"
          fullWidth
          variant="standard"
          value = {props.grouppingBooth}
          onChange={onChangeName}
        /> */}
        <FormControl margin="dense" variant="standard" fullWidth>
          <InputLabel id="demo-simple-select-label">Booth Number</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={props.grouppingBooth}
            label="Sales Form"
            onChange={onChangeName}
          >
            {
              booths?.map((booth_number, index)=>{
                return(
                  <MenuItem key={index} value={booth_number}>{booth_number}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <div id="previewSvg" style={{marginTop: 20, width:'100%', height:'400px'}}>
          
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOk}>Ok</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setMainLayers: layers=>dispatch(setMainLayers(layers)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),        
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(GrouppingModal);