import {
  SET_VIEW_DATA,
  SET_VIEW_File,
  SET_MAIN_CANVAS,
  SET_G_GRID,
  SET_BG_CANVAS,
  SET_BG_IMG_CANVAS,
  SET_LEFT_RULER,
  SET_TOP_RULER,
  SET_LAYERS,
  SET_PLANNER,
  SET_BOOKMARK_LIST,
  SET_IMPORT_LAYERS,
  SET_MAIN_SVG,
  SET_BOOTH_LIST,
  SET_BOOTH_MG_INFO,
  SET_BOOTH_FILE_LIST,
  SET_CANCAS_REFRESH,
  SET_LOADING,
  SET_BOOTH_FILE_LIST_UPDATED,
  SET_TO_2D,
  SET_BG_INFO,
  SET_BOOTH_INFO,
  SET_SHOW_GRID,
  SET_TURNON_SNAP,
  SET_ACTIVE_BOOTH_FILE,
  SET_ACTIVE_MAP_KEY,
  SET_GRID_ANGLE,
  SET_GRID_SIZE,
  SET_TRIGER_DRAW_GRID,
  SET_UNIT,
  SET_MENU_CONTROL,
  SET_DRAGGING_TARGET,
  SET_LAYER_BOOT_OUTLINE,
  SET_LAYER_BOOT_NUMBER,
  SET_LAYER_ANNOTATION,
  SET_LAYER_SPONSORSHIPS,
  SET_LAYER_VENUE,
  SET_LAYER_ORIGINAL,
  SET_LAYER_DELETED,
  SET_TOP_DRAWING,
  SET_ELEMENT_DRAWING,
  SET_BOOTH_LOADED,
  SET_GRID_OFFSET,
  SET_TRIGGER_RULER,
  RESET,
  SET_TRIGGER_CENTER_FOCUS,
  SET_SAVE_FILE_NAME,
  SET_DWG_IMPORTED,
  SET_PRICING_OPTIONS,
  SET_PREMIUM_OPTIONS,
  SET_DISCOUNT_OPTIONS,
  SET_GROUP_OPTIONS,
  SET_CART_LIST,
  SET_SPONSORSHIPS_OPTIONS,
  SET_EXHIBITOR_LIST,
  SET_CATEGORY_OPTIONS,
  SET_FEE_GROUP_OPTIONS,
  SET_FEE_CATEGORY_OPTIONS,
  SET_AUTH_INFO,
  SET_ACTIVE_3D_OBJECT,
  SET_ORG_JSON,
  SET_REMOVE_OBJECTS,
  SET_MAP_REMOVE_OBJECTS,
  SET_FIRST_MAP_CONTENT,
  SET_MECHANT_ACCOUNT,
  SET_FIRST_BG_CONTENT,
  SET_SHOW_SETUP,
  SET_OVERVIEW_OBJECT
} from '../actions/main';

var initVal = {
  planner: false,
  bookmarkList:[],
  orgJson: false,
  removeObjects: [],
  // mechantAccount: {},
  mapRemoveObjects: [],
  authInfo: {show_key:false, user_key:false, authenticated:1},
  pricingOptions:[],
  premiumOptions:[],
  discountOptions:[],
  categoryOptions:[],
  feeCategoryOptions:[],
  groupOptions:[],
  cartList:[],
  sponsorshipsOptions: [],
  exhibitorList: false,
  feeGroupOptions:[],
  showSetup:false,
  active_map_key:false,
  active_booth_file: {}, dwg_imported:false, view_data:null,mainCanvas:null,g_grid:null,bgCanvas:null,bgImgCanvas:null,topRuler:null,leftRuler:null, mainSvg:null, importLayers:[], mainLayers:[], layerSelect:{booth:true, booth_outline:true, venue:true, booth_number:true, annotation:true, sponsorships:true, original:false}, boothList:[], boothMgInfo:{}, boothFileList:[], boothFileListUpdated:false, loadingFlag:false, bgInfo:{}, showGrid:false, snapToGrid:false, gridAngle:0, gridSize:10, unit:'feet', menuControl:{},topDrawing:false, elementDrawing:false, gridOffset:{x:0, y:0}, saveFileName: null
}

var resetVal = {
  // pricingOptions:[],
  // premiumOptions:[],
  // discountOptions:[],
  // categoryOptions:[],
  // groupOptions:[],
  // feeGroupOptions:[],
  // feeCategoryOptions:[],
  active_map_key:false,
  active_booth_file: {}, dwg_imported:false, view_data:null, layerSelect:{booth:true, booth_outline:true, booth_number:true, annotation:true, sponsorships:true, original:false}, boothList:[], boothMgInfo:{},  loadingFlag:false, bgInfo:{}, showGrid:false, snapToGrid:false, gridAngle:0, gridSize:10, unit:'feet', menuControl:{},topDrawing:false, elementDrawing:false, gridOffset:{x:0, y:0}
}

export default function quote(state = initVal, {type, payload}) {
  switch (type) {
    case RESET:
      //console.log("RESET", payload)
      return {
        ...state,
        ...resetVal
    }
    case SET_AUTH_INFO:
      //console.log("SET_AUTH_INFO", payload)
      return {
        ...state,
        authInfo: payload
    }
    case SET_ACTIVE_3D_OBJECT:
      //console.log("SET_ACTIVE_3D_OBJECT", payload)
      return {
        ...state,
        active3dObject: payload
    }
    case SET_SAVE_FILE_NAME:
      //console.log("SET_SAVE_FILE_NAME", payload)
      return {
        ...state,
        saveFileName: payload
    }    
    case SET_REMOVE_OBJECTS:
      //console.log("SET_REMOVE_OBJECTS", payload)
      return {
        ...state,
        removeObjects: payload
    }
    case SET_MAP_REMOVE_OBJECTS:
      //console.log("SET_MAP_REMOVE_OBJECTS", payload)
      return {
        ...state,
        mapRemoveObjects: payload
    }
    case SET_MECHANT_ACCOUNT:
      //console.log("SET_MECHANT_ACCOUNT", payload)
      return {
        ...state,
        mechantAccount: payload
    }
    case SET_SHOW_SETUP:
      //console.log("SET_SHOW_SETUP", payload)
      return {
        ...state,
        showSetup: payload
    }
    case SET_FIRST_MAP_CONTENT:
      //console.log("SET_FIRST_MAP_CONTENT", payload)
      return {
        ...state,
        firstMapContent: payload
    }
    case SET_FIRST_BG_CONTENT:
      //console.log("SET_FIRST_BG_CONTENT", payload)
      return {
        ...state,
        firstBgContent: payload
    }
    case SET_ORG_JSON:
      //console.log("SET_ORG_JSON", payload)
      return {
        ...state,
        orgJson: payload
    }
    case SET_PRICING_OPTIONS:
      //console.log("SET_PRICING_OPTIONS", payload)
      return {
        ...state,
        pricingOptions: payload
    }
    case SET_PREMIUM_OPTIONS:
      //console.log("SET_PREMIUM_OPTIONS", payload)
      return {
        ...state,
        premiumOptions: payload
    }
    case SET_DISCOUNT_OPTIONS:
      //console.log("SET_DISCOUNT_OPTIONS", payload)
      return {
        ...state,
        discountOptions: payload
    }
    case SET_GROUP_OPTIONS:
      //console.log("SET_GROUP_OPTIONS", payload)
      return {
        ...state,
        groupOptions: payload
    }
    case SET_CART_LIST:
      //console.log("SET_GROUP_OPTIONS", payload)
      return {
        ...state,
        cartList: payload
    }
    case SET_SPONSORSHIPS_OPTIONS:
      //console.log("SET_SPONSORSHIPS_OPTIONS", payload)
      return {
        ...state,
        sponsorshipsOptions: payload
    }
    case SET_EXHIBITOR_LIST:
      return {
        ...state,
        exhibitorList: payload
    }    
    case SET_CATEGORY_OPTIONS:
      //console.log("SET_CATEGORY_OPTIONS", payload)
      return {
        ...state,
        categoryOptions: payload
    }
    case SET_FEE_GROUP_OPTIONS:
      //console.log("SET_FEE_GROUP_OPTIONS", payload)
      return {
        ...state,
        feeGroupOptions: payload
    }
    case SET_FEE_CATEGORY_OPTIONS:
      //console.log("SET_FEE_CATEGORY_OPTIONS", payload)
      return {
        ...state,
        feeCategoryOptions: payload
    }
    case SET_DWG_IMPORTED:
      //console.log("SET_DWG_IMPORTED", payload)
      return {
        ...state,
        dwg_imported: payload
    }
    case SET_TRIGGER_RULER:
      //console.log("SET_TRIGGER_RULER", payload)
      return {
        ...state,
        triggerRuler: payload
    }
    case SET_GRID_OFFSET:
      //console.log("SET_GRID_OFFSET", payload)
      return {
        ...state,
        gridOffset: payload
    }
    case SET_TOP_DRAWING:
      return {
        ...state,
        topDrawing: payload,
        elementDrawing: false
    }
    case SET_ELEMENT_DRAWING:
      return {
        ...state,
        elementDrawing: payload,
        topDrawing: false
    }
    case SET_BOOTH_LOADED:
      return {
        ...state,
        boothLoaded: payload
      }
    case SET_LOADING:
      return {
        ...state,
        loadingFlag: payload
      }
      case SET_LAYER_BOOT_OUTLINE:
        return {
          ...state,
          layerSelect: {...state.layerSelect, booth_outline:payload}
      }
      case SET_LAYER_BOOT_NUMBER:
        return {
          ...state,
          layerSelect: {...state.layerSelect, booth_number:payload}
      }
      case SET_LAYER_ANNOTATION:
        return {
          ...state,
          layerSelect: {...state.layerSelect, annotation:payload}
      }
      case SET_LAYER_SPONSORSHIPS:
        return {
          ...state,
          layerSelect: {...state.layerSelect, sponsorships:payload}
      }
      case SET_LAYER_VENUE:
        return {
          ...state,
          layerSelect: {...state.layerSelect, venue:payload}
      }
      case SET_LAYER_ORIGINAL:
        return {
          ...state,
          layerSelect: {...state.layerSelect, original:payload}
      }
      case SET_LAYER_DELETED:
        return {
          ...state,
          layerSelect: {...state.layerSelect, deleted:payload}
      }
      case SET_VIEW_DATA:
        return {
          ...state,
          view_data: payload
        }
      case SET_VIEW_File:
        return {
          ...state,
          view_file: payload
        }      
      case SET_ACTIVE_BOOTH_FILE:
        return {
          ...state,
          active_booth_file: payload
        }
      case SET_ACTIVE_MAP_KEY:
        return {
          ...state,
          active_map_key: payload
        }
      case SET_MAIN_CANVAS:
        return {
          ...state,
          mainCanvas: payload
        }
      case SET_G_GRID:
        return {
          ...state,
          g_grid: payload
        }
      case SET_BG_CANVAS:
        return {
          ...state,
          bgCanvas: payload
        }
      case SET_BG_IMG_CANVAS:
        return {
          ...state,
          bgImgCanvas: payload
        }
      case SET_LEFT_RULER:
        return {
          ...state,
          leftRuler: payload
        }
      case SET_TOP_RULER:
        return {
          ...state,
          topRuler: payload
        }
      case SET_LAYERS:
        return {
          ...state,
          mainLayers: payload
        }
      case SET_PLANNER:
        return {
          ...state,
          [`planner-${payload.key}`]: payload.tokenInfo
        }
      case SET_BOOKMARK_LIST:
        return {
          ...state,
          bookmarkList: payload
        }
      case SET_IMPORT_LAYERS:
        return {
          ...state,
          importLayers: payload
        }
      case SET_MAIN_SVG:
        return {
          ...state,
          mainSvg: payload
        }
      case SET_BG_INFO:
        console.log("SET_BG_INFO", payload)
        console.log("SET_BG_INFO", state.active_booth_file)
        return {
          ...state,
          active_booth_file:{...state.active_booth_file, ...payload}
        }
      case SET_BOOTH_INFO:
        return {
          ...state,
          active_booth_file:{...state.active_booth_file, ...payload}
        }
      case SET_SHOW_GRID:
        return {
          ...state,
          showGrid: payload
        }
      case SET_TURNON_SNAP:
        return {
          ...state,
          snapToGrid: payload
        }
      case SET_GRID_ANGLE:
        return {
          ...state,
          gridAngle: payload
        }
      case SET_GRID_SIZE:
        return {
          ...state,
          gridSize: payload
        }
      case SET_TRIGER_DRAW_GRID:
        return {
          ...state,
          triggerDrawGrid: payload
        }
      case SET_UNIT:
        return {
          ...state,
          unit: payload
        }
      case SET_MENU_CONTROL:
        return {
          ...state,
          menuControl: payload
        }
      case SET_BOOTH_LIST:
        //console.log("SET_BOOTH_LIST", payload)
        return {
          ...state,
          boothList: payload
        }
      case SET_BOOTH_MG_INFO:
        //console.log("SET_BOOTH_MG_INFO", payload)
        return {
          ...state,
          boothMgInfo: payload
        }
      case SET_BOOTH_FILE_LIST:
        //console.log("SET_BOOTH_FILE_LIST", payload)
        return {
          ...state,
          boothFileList: payload,
          boothFileListUpdated: true
        }
      case SET_BOOTH_FILE_LIST_UPDATED:
        //console.log("SET_BOOTH_FILE_LIST_UPDATED", payload)
        return {
          ...state,
          boothFileListUpdated: payload
        }
      case SET_CANCAS_REFRESH:
        //console.log("SET_CANCAS_REFRESH", payload)
        return {
          ...state,
          canvasRefresh: payload
        }      
      case SET_TO_2D:
        //console.log("SET_TO_2D", payload)
        return {
          ...state,
          to2dFlag: payload
        }      
      case SET_DRAGGING_TARGET:
        //console.log("SET_DRAGGING_TARGET", payload)
        return {
          ...state,
          draggingTarget: payload
        }      
      case SET_TRIGGER_CENTER_FOCUS:
        //console.log("SET_TRIGGER_CENTER_FOCUS", payload)
        return {
          ...state,
          triggerCenterFocus: payload
        }        
      case SET_OVERVIEW_OBJECT:
        return {
          ...state,
          overviewObject: payload
        }       
        
      default:
          return state;
  }
}